import React from 'react';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import { LuMoveRight } from 'react-icons/lu';
import frame from '../../assets/frame.png';
import isomo from '../../assets/isomo.png';
import Partners from '../partners/Partners';
import Footer from '../../components/Footer';

const dummyOpportunities = [
  {
    id: 1,
    title: 'Isomo Academy 2024',
    description:
      'Applications open from December 18, 2023 to April 22, 2024 For more details and application go to Bridge2 Rwanda or use the following link ',
    date: 'Feb 21,2024',
    image: isomo,
  },
];
const AllOpportunities = ({ setChosenOpportunity, setNavigateToSection }) => {
  return (
    <div className="w-full">
      <div className="w-full flex flex-col px-6 md:px-12 py-6 lg:px-32 lg:py-12">
        <div
          className="bg-cover bg-center flex flex-col lg:flex-row items-center justify-between font-rubik max-h-[250px] lg:h-[150px] w-full px-12 py-6 rounded-2xl"
          style={{ backgroundImage: `url(${frame})` }}
        >
          <div className="flex flex-col gap-1">
            <h1 className="text-secondary font-bold text-2xl lg:text-4xl font-quicksand">
              Opportunities
            </h1>
          </div>
          <p className=" w-full text-center lg:text-start lg:w-[40%]  text-white font-rubik text-base lg:text-lg">
            Discover opportunities to make a difference: get involved with our
            organisation.
          </p>
        </div>

        <h1 className="text-2xl font-quicksand text-primary font-bold my-6">
          Recent Opportunities
        </h1>

        <div className="w-full flex flex-col lg:flex-row gap-4">
          {dummyOpportunities.map((opportunity) => {
            return (
              <div className="bg-grey rounded-2xl gap-4 flex items-start w-full lg:w-1/2 p-4">
                <div className="max-w-[200px] h-[120px] bg-cover rounded-2xl overflow-hidden">
                  <img src={isomo} alt="" className="w-full h-full bg-cover" />
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-fontGrey text-sm">{opportunity.date}</p>
                  <p className="font-quicksand font-bold text-2xl">
                    {opportunity.title.length > 30
                      ? opportunity.title.slice(0, 30) + '...'
                      : opportunity.title}
                  </p>

                  <p className="font-rubik text-fontGrey">
                    {opportunity.description.length > 80
                      ? opportunity.description.slice(0, 80) + '...'
                      : opportunity.description}
                  </p>

                  <Link to={'/opportunities/single'} className="w-fit">
                    <div
                      className="flex gap-2 items-center w-fit cursor-pointer"
                      onClick={() => {
                        setChosenOpportunity(opportunity.id);
                      }}
                    >
                      <p className="text-secondary font-rubik">Read more</p>
                      <LuMoveRight size={20} color="#FDB935" />
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Partners />
      <Footer setNavigateToSection={setNavigateToSection} />
    </div>
  );
};

export default AllOpportunities;
