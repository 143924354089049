import React from 'react';
import Gilbert from '../../../../assets/Gilbert.png';
import Sandra from '../../../../assets/Sandra.jpg';
import Blaise from '../../../../assets/Blaise.png';
import Diane from '../../../../assets/Diane.jpg';
import Joseph from '../../../../assets/Joseph.png';
import Jolly from '../../../../assets/Jolly.png';
import Nicole from '../../../../assets/Nicole.png';
import Innocent from '../../../../assets/Innocent.jpg';
import Consolee from '../../../../assets/consolee.png';
import Epimaque from '../../../../assets/epimaque.png';
import Theogene from '../../../../assets/theogene.png';
import Cedrick from '../../../../assets/Cedrick.png';

const Team = () => {
  return (
    <div id="team" className="w-full px-6 md:px-12 py-6 lg:px-32 lg:py-12">
      <div className="flex items-center gap-2 mb-4">
        <div className="bg-secondary w-[80px] h-[4px]"></div>
        <p className="text-secondary text-lg">TEAM</p>
      </div>
      <h1 className="text-primary font-quicksand font-bold mb-9 text-4xl">
        Meet The Team
      </h1>
      <p className="font-rubik text-base text-center lg:text-start mb-12">
        The ASEF team consists of highly skilled and experienced professionals
        who are dedicated to supporting students in reaching their full
        potential.
      </p>
      <div className="flex flex-wrap gap-8 justify-center">
        <div className="flex flex-col gap-2 max-w-[230px]">
          <div className="max-w-[230px] h-[250px]  overflow-hidden rounded-2xl">
            <img
              src={Gilbert}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">
            Ndikubwimana Gilbert
          </p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Program Director
          </p>
        </div>

        <div className="flex flex-col gap-2 max-w-[230px]">
          <div className="h-[250px] w-full overflow-hidden rounded-2xl">
            <img
              src={Jolly}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl w-fit">
            Jolly Nyiratunga
          </p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C] w-fit">
            Community Outreach Director
          </p>
        </div>

        <div className="flex flex-col gap-2 max-w-[230px]">
          <div className="w-full h-[250px] overflow-hidden rounded-2xl">
            <img
              src={Blaise}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">Mucyo Braise</p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Student Outreach Director
          </p>
        </div>

        <div className="flex flex-col gap-2 max-w-[230px]">
          <div className="w-full h-[250px] overflow-hidden rounded-2xl">
            <img
              src={Sandra}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">
            Sandra Gikundiro
          </p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Field Coordinator
          </p>
        </div>

        <div className="flex flex-col gap-2 w-[230px]">
          <div className="w-full h-[250px] overflow-hidden rounded-2xl">
            <img
              src={Nicole}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">Uwase Nicole</p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Field Coordinator
          </p>
        </div>

        <div className="flex flex-col gap-2 w-[230px]">
          <div className="h-[250px] max-w-[300] overflow-hidden rounded-2xl">
            <img
              src={Innocent}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">
            Iradukunda Innocent
          </p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Field Coordinator
          </p>
        </div>

        <div className="flex flex-col gap-2 w-[230px]">
          <div className="w-full h-[250px] overflow-hidden rounded-2xl">
            <img
              src={Diane}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">
            Umutoniwase Diane
          </p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Program Associate
          </p>
        </div>

        <div className="flex flex-col gap-2 w-[230px]">
          <div className="w-full h-[250px] overflow-hidden rounded-2xl">
            <img
              src={Consolee}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">
            Munezero Consolee
          </p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Field Associate
          </p>
        </div>

        <div className="flex flex-col gap-2 w-[230px]">
          <div className="w-full h-[250px] overflow-hidden rounded-2xl">
            <img
              src={Theogene}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">
            Nayituriki Theogene
          </p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Field Associate
          </p>
        </div>

        <div className="flex flex-col gap-2 w-[230px]">
          <div className="w-full h-[250px] overflow-hidden rounded-2xl">
            <img
              src={Epimaque}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">
            Nizeyimana Epimaque
          </p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Field Associate
          </p>
        </div>

        <div className="flex flex-col gap-2 w-[230px]">
          <div className="w-full h-[250px] overflow-hidden rounded-2xl">
            <img
              src={Joseph}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">
            Nteziryayo Joseph
          </p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Operations Assistant
          </p>
        </div>

        <div className="flex flex-col gap-2 w-[230px]">
          <div className="w-full h-[250px] overflow-hidden rounded-2xl">
            <img
              src={Cedrick}
              alt=""
              className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
            />
          </div>
          <p className="font-quicksand font-semibold text-xl">
            Twahirwa Cedrick
          </p>

          <p className="font-quicksand font-medium text-base italic text-[#6C6C6C]">
            Driver
          </p>
        </div>
      </div>
    </div>
  );
};

export default Team;
