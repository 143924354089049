import React from 'react';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';
import frame from '../../../assets/frame.png';
import { BiSolidQuoteAltLeft } from 'react-icons/bi';
import { LuMoveRight } from 'react-icons/lu';
import elie from '../../../assets/elie.png';
import ghad from '../../../assets/ghad.png';
import emmanuel from '../../../assets/emmanuel.png';
import Partners from '../../partners/Partners';
import Footer from '../../../components/Footer';

const stories = [
  {
    id: 1,
    name: 'Ntakirutimana Elie',
    role: 'Student at cornell  University',
    location: 'USA',
    image: elie,
    message:
      'I am known for my unwavering spirit, empathy, and resilience. In the face of adversity, I love demonstrating unshakable determination and a relentless pursuit of self-improvement. My defining trait is my deep desire to make a difference,with dreams rooted in altruism and a commitment to transforming the lives of the vulnerable.',
  },
  {
    id: 2,
    name: 'Nkurunziza Ghad',
    role: 'Student at Wesleyan University',
    location: 'USA',
    image: ghad,
    message:
      'Growing up as the firstborn, I grew up in a low-income family of two parents and four siblings in the rural area part of Rwanda, Burera-Butaro. Looking at myself, risk-taking, persistence and a growth mindset define the truest essence of my personality',
  },
  {
    id: 3,
    name: 'Nizeyimana Emmanuel',
    role: 'Student at Gahini GS',
    location: 'RWANDA',
    image: emmanuel,
    message:
      "Njyewe natangiye kwiga ntinze bitewe n'imyumvire ndetse n'ubushobozi bw'umuryango nkomokamo. Njyeze mu mwaka wa gatandatu w'amashuri abanza nafataga umwanya nkajya ku muhanda mugihe abanyeshuri biga mu mashuri yisumbuye babaga bahetse ibikapu bagiye",
  },
];

const Stories = ({ setChosenStory, setNavigateToSection }) => {
  return (
    <div className="w-full h-fit">
      <div className="w-full flex flex-col px-6 md:px-12 py-6 lg:px-32 lg:py-12">
        <div
          className="bg-cover bg-center flex flex-col lg:flex-row items-center justify-between font-rubik max-h-[250px] lg:h-[150px] w-full px-12 py-6 rounded-2xl"
          style={{ backgroundImage: `url(${frame})` }}
        >
          <div className="flex flex-col gap-1">
            <h1 className="text-secondary font-bold text-2xl lg:text-4xl font-quicksand">
              Stories
            </h1>
          </div>
          <p className=" w-full text-center lg:text-start lg:w-[40%]  text-white font-rubik text-base lg:text-lg">
            We share our student successful stories, learn more from our
            students' inspiring stories.
          </p>
        </div>

        <p className="text-primary text-2xl lg:text-4xl font-quicksand font-semibold mt-6 mb-12">
          Latest Alumni Stories
        </p>

        <div className="flex flex-row flex-wrap w-full">
          {stories.map((story) => {
            return (
              <div className="flex flex-col lg:flex-row gap-6 items-start w-full lg:w-[49%] px-1 py-4">
                <div className="w-fit lg:max-w-[160px] h-[160px] rounded-2xl bg-cover overflow-hidden mx-auto">
                  <img
                    src={story.image}
                    alt=""
                    className="w-full h-full bg-cover"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full lg:w-2/3">
                  <h2 className="font-bold font-quicksand text-center lg:text-start">
                    {story.name}
                  </h2>
                  <p className="font-rubik text-sm text-fontGrey text-center lg:text-start">
                    {story.role}
                  </p>

                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <BiSolidQuoteAltLeft
                        size={20}
                        color="#FDB935"
                        className="mx-auto lg:mx-0"
                      />
                      <p>
                        {story.message.length > 200
                          ? story.message.slice(0, 200) + '...'
                          : story.message}
                      </p>
                    </div>

                    <Link
                      to={'/stories/single'}
                      className="w-fit"
                      onClick={() => {
                        setChosenStory(story.id);
                      }}
                    >
                      <div className="flex gap-2 items-center w-fit cursor-pointer">
                        <p className="text-secondary font-rubik">Read more</p>
                        <LuMoveRight size={20} color="#FDB935" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Partners />
      <Footer setNavigateToSection={setNavigateToSection} />
    </div>
  );
};

export default Stories;
