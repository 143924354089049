import React from 'react';
import { LuMoveRight } from 'react-icons/lu';
import Partners from '../partners/Partners';
import Footer from '../../components/Footer';
import isomo from '../../assets/isomo.png';

const opportunityArray = [
  {
    id: 1,
    image: isomo,
    date: 'Feb 16, 2024',
    title: 'Isomo Academy 2024',
    paragraphs: [
      {
        text: 'Applications open from December 18, 2023 to April 22, 2024For more details and application go to Bridge2 Rwanda or use the following link: ',
        linkObj: {
          new_line: true,
          link: 'https://www.bridge2rwanda.org/isomo-academy/isomo-application/',
        },
      },

      {
        text: 'Isomo Academy 2024 Application',
      },
      {
        text: 'The following information will help you understand our program better before submitting your application. Please read carefully.',
      },
      {
        text: 'The program will help students develop foundational English language skills in reading, writing, listening, and speaking, and broaden their worldview through readings that sharpen their critical thinking and engage all areas of their personal growth to enhance their leadership skills.',
      },
      {
        text: "Isomo Academy is designed to help Rwanda's top high school students with the skills they need to compete and win university scholarships inside and outside Rwanda.",
      },
      {
        text: 'Applicants must be in S4 to be eligible.',
      },
      {
        text: 'This is a 8-week, residential program beginning in mid-July to mid-September 2024',
      },
      {
        text: 'Participation in Isomo Academy does not make students B2R Scholars, nor does it guarantee a university scholarship.',
      },
      {
        text: 'If you understand and are in agreement with these statements, we invite you to complete the following application.',
      },
      {
        text: 'The application period closes Monday, April 22, 2024, at 8:00pm.',
      },
      {
        text: 'Note: Please make sure your phone number and email address work well before starting this application.',
      },
    ],
  },
];
const Opportunity = ({
  chosenOpportunity,
  setChosenOpportunity,
  setNavigateToSection,
}) => {
  return (
    <div className="w-full h-fit">
      <div className="w-full px-6 lg:px-32 pb-12 pt-6">
        <div className="flex flex-col lg:flex-row items-start w-full gap-6">
          <div className="flex flex-col lg:w-2/3">
            {opportunityArray.map((opportunity) => {
              if (opportunity.id == chosenOpportunity) {
                return (
                  <div className="flex flex-col w-full">
                    <div className=" bg-black bg-cover w-full overflow-hidden rounded-3xl max-h-[600px] object-contain">
                      <img
                        src={opportunity.image}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>

                    <div className=" flex items-center gap-4 my-3">
                      <p className="text-fontGrey">{opportunity.date}</p>
                    </div>

                    <h1 className="mt-1 font-bold text-2xl text-start">
                      {opportunity.title}
                    </h1>

                    <div className="mt-4 flex flex-col text-lg font-rubik text-fontGrey gap-4">
                      {opportunity.paragraphs.map((paragraph) => {
                        if (paragraph.linkObj) {
                          if (paragraph.linkObj.new_line) {
                            return (
                              <>
                                <p>{paragraph.text}</p>
                                <a
                                  href={paragraph.linkObj.link}
                                  target="_blank"
                                  className="font-medium text-primary underline w-fit"
                                >
                                  {paragraph.linkObj.link}
                                </a>
                              </>
                            );
                          } else {
                            return (
                              <div className="flex items-center flex-wrap">
                                <p>{paragraph.text} + </p>
                                <p className="font-medium text-primary ml-2">
                                  {paragraph.linkObj.link}
                                </p>
                              </div>
                            );
                          }
                        } else {
                          return <p>{paragraph.text}</p>;
                        }
                      })}
                    </div>

                    {/* <div className="my-6 lg:my-12 flex gap-6 font-rubik">
                    <p className="text-lg">Share</p>
                    <div className="flex items-center gap-4">
                      <FaInstagram size={20} color="#01A653" />
                      <FaFacebook size={20} color="#01A653" />
                      <FaXTwitter size={20} color="#01A653" />
                    </div>
                  </div> */}
                  </div>
                );
              } else return null;
            })}
          </div>
          <div className="flex flex-col lg:w-1/3 bg-grey py-4 px-8 rounded-2xl font-rubik">
            <p className="text-lg font-semibold mb-4">Recent opportunities</p>
            <div className="flex flex-col gap-4">
              {opportunityArray.map((opportunity) => {
                return (
                  <div className="bg-white rounded-xl p-4 flex flex-col">
                    <div className=" flex items-center gap-4 my-3">
                      <p className="text-secondary">{opportunity.category}</p>
                      <p className="text-fontGrey">{opportunity.date}</p>
                    </div>
                    <p className="font-semibold mb-4">
                      {' '}
                      {opportunity.title.length > 40
                        ? opportunity.title.slice(0, 40) + '...'
                        : opportunity.title}
                    </p>
                    <p className="">
                      {opportunity.paragraphs[0].text.length > 80
                        ? opportunity.paragraphs[0].text.slice(0, 80) + '...'
                        : opportunity.paragraphs[0].text}
                    </p>

                    <div
                      className="mt-2 flex gap-2 items-center w-fit cursor-pointer"
                      onClick={() => {
                        setChosenOpportunity(opportunity.id);
                      }}
                    >
                      <p className="text-secondary font-rubik">Read more</p>
                      <LuMoveRight size={20} color="#FDB935" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Partners />
      <Footer setNavigateToSection={setNavigateToSection} />
    </div>
  );
};

export default Opportunity;
