import React, { Suspense, useState, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './components/TopBar';
import MainRoutes from './Routes/mainRoutes';
import Loader from './components/Loader';
import { ToasterComponent } from './components/Toast';
import AllOpportunities from './pages/opportunities/AllOpportunities';
import Opportunity from './pages/opportunities/Opportunity';
import GraduateMeetings from './pages/programs/detail/programs_pages/GraduateMeetings';
import Initiative from './pages/initiatives/Initiative';
import AllInitiatives from './pages/initiatives/AllInitiatives';
import Story from './pages/stories/detail/Story';
const About = lazy(() => import('./pages/about/detail/About'));
const Programs = lazy(() => import('./pages/programs/detail/Programs'));
const News = lazy(() => import('./pages/news/detail/News'));
const NewsPage = lazy(() => import('./pages/news/detail/news_pages/NewsPage'));
const Stories = lazy(() => import('./pages/stories/detail/Stories'));
const NewsLetter = lazy(() => import('./pages/newsletter/NewsLetter'));
const NewIntake = lazy(() =>
  import('./pages/programs/detail/programs_pages/NewIntake')
);
const AdvisoryMeetings = lazy(() =>
  import('./pages/programs/detail/programs_pages/AdvisoryMeetings')
);
const AnnualCamping = lazy(() =>
  import('./pages/programs/detail/programs_pages/AnnualCamping')
);

const Jadf = lazy(() => import('./pages/programs/detail/programs_pages/Jadf'));

const Supplies = lazy(() =>
  import('./pages/programs/detail/programs_pages/Supplies')
);

const SchoolVisits = lazy(() =>
  import('./pages/programs/detail/programs_pages/SchoolVisits')
);
const FAQPage = lazy(() => import('./pages/faqs/FAQPage'));

const App = () => {
  const [chosenNews, setChosenNews] = useState(1);
  const [chosenOpportunity, setChosenOpportunity] = useState(1);
  const [chosenInitiative, setChosenInitiative] = useState(1);
  const [chosenStory, setChosenStory] = useState(1);
  const [navigateToSection, setNavigateToSection] = useState('');
  return (
    <Router>
      <ToasterComponent />
      <Suspense fallback={<Loader />}>
        <TopBar setNavigateToSection={setNavigateToSection} />
        <Routes>
          <Route
            path="*"
            element={
              <MainRoutes
                setNavigateToSection={setNavigateToSection}
                setChosenNews={setChosenNews}
                setChosenStory={setChosenStory}
              />
            }
          />
          <Route
            path={`/about-us`}
            element={
              <About
                navigateToSection={navigateToSection}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />
          <Route
            path={`/news/single`}
            element={
              <NewsPage
                setNavigateToSection={setNavigateToSection}
                chosenNews={chosenNews}
                setChosenNews={setChosenNews}
              />
            }
          />
          <Route
            path={`/newsletter`}
            element={<NewsLetter setNavigateToSection={setNavigateToSection} />}
          />
          <Route
            path={`/programs`}
            element={
              <Programs
                navigateToSection={navigateToSection}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />
          <Route
            path={`/programs/new-intake`}
            element={<NewIntake setNavigateToSection={setNavigateToSection} />}
          />
          <Route
            path={`/programs/advisory-meetings`}
            element={
              <AdvisoryMeetings setNavigateToSection={setNavigateToSection} />
            }
          />
          <Route
            path={`/programs/annual-camping`}
            element={
              <AnnualCamping setNavigateToSection={setNavigateToSection} />
            }
          />

          <Route
            path={`/programs/jadf`}
            element={<Jadf setNavigateToSection={setNavigateToSection} />}
          />
          <Route
            path={`/programs/supplies`}
            element={<Supplies setNavigateToSection={setNavigateToSection} />}
          />
          <Route
            path={`/programs/school-visits`}
            element={
              <SchoolVisits setNavigateToSection={setNavigateToSection} />
            }
          />

          <Route
            path={`/programs/graduate-meetings`}
            element={
              <GraduateMeetings setNavigateToSection={setNavigateToSection} />
            }
          />

          <Route
            path={`/opportunities`}
            element={
              <AllOpportunities
                setChosenOpportunity={setChosenOpportunity}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />

          <Route
            path={`/opportunities/single`}
            element={
              <Opportunity
                chosenOpportunity={chosenOpportunity}
                setChosenOpportunity={setChosenOpportunity}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />

          <Route
            path={`/initiatives`}
            element={
              <AllInitiatives
                setChosenInitiative={setChosenInitiative}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />

          <Route
            path={`/initiatives/single`}
            element={
              <Initiative
                chosenInitiative={chosenInitiative}
                setChosenInitiative={setChosenInitiative}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />

          <Route
            path={`/news`}
            element={
              <News
                setChosenNews={setChosenNews}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />

          <Route
            path={`/stories`}
            element={
              <Stories
                setChosenStory={setChosenStory}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />
          <Route
            path={`/stories/single`}
            element={
              <Story
                chosenStory={chosenStory}
                setChosenStory={setChosenStory}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />
          <Route
            path={`/faq`}
            element={<FAQPage setNavigateToSection={setNavigateToSection} />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
