import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import Instagram from './detail/Instagram';
import Twitter from './detail/Twitter';

const SocialMedia = () => {
  return (
    <div className="flex flex-col lg:flex-row w-full h-fit lg:min-h-full overflow-x-hidden">
      <div className="flex flex-col w-full px-6 md:px-12 lg:px-24 py-12">
        <div className="flex items-center justify-center gap-2 mb-6">
          <div className="bg-secondary w-[80px] h-[4px]"></div>
          <h1 className="text-secondary font-semibold font-rubik">
            SOCIAL MEDIA
          </h1>
          <div className="bg-secondary w-[80px] h-[4px]"></div>
        </div>

        <h2 className="font-bold text-quicksand text-center text-3xl lg:text-[41px] text-primary mb-6 w-full lg:w-[60%] mx-auto">
          Stay Updated with ASEF's Social Media Posts!
        </h2>

        <div className="flex flex-col lg:flex-row items-center gap-12 w-full justify-center mt-6">
          {/* <div className=" flex flex-col gap-4 w-full lg:w-[60%]">
            <div className="bg-lightGrey py-3 px-6 w-fit rounded-lg">
              <div className="flex items-center gap-3 font-rubik">
                <h1>From</h1>
                <a
                  href="https://www.instagram.com/asefrwanda/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-black rounded-full p-[6px] cursor-pointer"
                >
                  <FaInstagram size={15} color="#FFFFFF" />
                </a>
                <h1>Instagram</h1>
              </div>
            </div>
            <div className="w-full h-[600px] overflow-x-hidden overflow-y-auto rounded-2xl shadow-md p-1 ">
              <Instagram />
            </div>
          </div> */}
          <div className="flex flex-col gap-4 w-full lg:w-[40%]">
            <div className="mx-auto bg-lightGrey py-3 px-6 w-fit rounded-lg">
              <div className="flex items-center gap-3 font-rubik">
                <h1>From</h1>
                <a
                  href="https://twitter.com/ASEFRwanda"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-black rounded-full p-[6px] cursor-pointer"
                >
                  <FaXTwitter size={15} color="#FFFFFF" />
                </a>
                <h1>X</h1>
              </div>
            </div>
            <Twitter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
