import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import { BiSolidQuoteAltLeft } from 'react-icons/bi';
import { LuMoveRight } from 'react-icons/lu';
import elie from '../../../assets/elie.png';
import ghad from '../../../assets/ghad.png';
import emmanuel from '../../../assets/emmanuel.png';
import Partners from '../../partners/Partners';
import Footer from '../../../components/Footer';

const stories = [
  {
    id: 1,
    name: 'Ntakirutimana Elie',
    role: 'Student at cornell  University',
    location: 'USA',
    image: elie,
    paragraphs: [
      {
        text: 'I am known for my unwavering spirit, empathy, and resilience. In the face of adversity, I love demonstrating unshakable determination and a relentless pursuit of self-improvement. My defining trait is my deep desire to make a difference,with dreams rooted in altruism and a commitment to transforming the lives of the vulnerable.',
      },
      {
        text: "I have been supported by ASEF in my advanced level from 2019 to 2022. I began my academic pursuits at GS Kivumu, a lesser-known public school, during my ordinary-level studies. However, a turning point came when I achieved exceptional results at the district level in Kamonyi, leading me to apply to ASEF, which subsequently became the catalyst for my dreams. ASEF's support enabled me to enroll at the prestigious GSO Butare. There, I seized every opportunity, excelling in competitions like the Wavumbuzi Entrepreneurship Challenge, where I secured first place in Water Quest at a national level. Furthermore, I proudly ranked fifth in General Education countrywide during the Advanced Level National Examinations, a testament to my dedication and growth.",
      },
      {
        text: "I'm currently pursuing my Bachelor's Degree in Agricultural Sciences at Cornell University, an esteemed Ivy League institution in New York, US. After completing Senior 5, I had the opportunity to join Isomo Academy, an English immersion program affiliated with Bridge2Rwanda. My college counselors at Bridge2Rwanda are the ones who told me to apply to Cornell. They played a pivotal role in my college application process, assisting me in selecting schools, crafting essays, and equipping me with essential information about college life. Their support has been instrumental in my educational journey.",
      },
      {
        text: "Seeking admission to a prestigious university requires more than good grades; it necessitates a foundation of values and unwavering commitment. The advice is straight forward: First, know that you went to study not to school, ''view your education as an opportunity, work diligently, dream ambitiously, stay open-minded, and seek opportunities. Understand that challenges are inevitable on the path to such universities, but after every setback, there's a chance for success. Cultivate curiosity, as it fuels the quest for knowledge, and embrace failures as valuable lessons. In essence, combine academic excellence with resilience, ambition, curiosity, and a growth mindset to reach your goals. Never give up!",
      },
    ],
  },
  {
    id: 2,
    name: 'Nkurunziza Ghad',
    role: 'Student at Wesleyan University',
    location: 'USA',
    image: ghad,
    paragraphs: [
      {
        text: 'Growing up as the firstborn, I grew up in a low-income family of two parents and four siblings in the rural area part of Rwanda, Burera-Butaro. Looking at myself, risk-taking, persistence and a growth mindset define the truest essence of my personality',
      },
      {
        text: "2016 is when I joined ES Runaba in O' level. Confused like any other senior 1 student, I had no clear path of my future ambitions.When I went to classes, my English was questionable, my critical thinking skills were still sleeping, and my goal-setting skills were still young. However, I remember the day I had a conversation with my physics teacher, that's when I realized that the future holds something for me and what I needed only was sacrificing my time and spending it on books and classes. Going to an advanced level, the vision was bigger.With the primary intention of seeing the plane with my eyes, I decided to choose ES.KANOMBE/EFOTEC over any other other school countrywide. Honestly speaking, no specific thing I knew about EFOTEC except that it was near the Kigali International Airport.",
      },

      {
        text: "In the midst of Covid-19, God revealed the bride to my destiny; Isomo Academy. Without hesitation, I applied and fortunately was accepted to join the cohort of 120 brilliant students from all over the country. Being in ISOMO Academy opened my eyes to the world outside the books and classrooms, and taught me the importance of looking around and giving a hand to the needy. From Isomo, that's where I learned that nothing is impossible; just it requires a trial denies mediocrity",
      },
      {
        text: "Beyond community engagement and class reading, applying for college was the next. This was the most challenging and toughest journey of my education career. I applied to 20 colleges in the US, but the results were heartbreaking. After 19 depressing moments of rejection, I was accepted into Wesleyan University and this proves that multiple failures are not the end of someone's life. Today, I'm in college, but whenever I look back to my past, the obstacles on my way to this milestone were uncountable, but persistence determination, and God's blessing only guaranteed me victory",
      },
      {
        text: "Beautiful ASEF's daughters and sons, remember that it's not too late to pursue your dreams. Build a strong heart to stand the rejections and failures, but remember that only time heals. Get rid of mediocrity, and always read books; that's how you fight self-supported ignorance. The world has many opportunities, but remember opportunities will never chase you; go chase them. Find something you're better at, free yourself, and hunt for excellence in it. Your teachers, classmates, Deans of studies, parents, neighbors, and friends, everyone can be a bridge to your success; respect and learn from them. But remember, people are different; don't surround yourself with people who don't value your dreams they can make you lose track of the final point. More importantly, success is always worked for, so stay motivated, work a bit harder, and maintain excellent discipline.",
      },
    ],
  },
  {
    id: 3,
    name: 'Nizeyimana Emmanuel',
    role: 'Student at Gahini GS',
    location: 'RWANDA',
    image: emmanuel,
    paragraphs: [
      {
        text: "Njyewe natangiye kwiga ntinze bitewe n'imyumvire ndetse n'ubushobozi bw'umuryango nkomokamo. Njyeze mu mwaka wa gatandatu w'amashuri abanza nafataga umwanya nkajya ku muhanda mugihe abanyeshuri biga mu mashuri yisumbuye babaga bahetse ibikapu bagiye ku bigo biga babamo. ",
      },
      {
        text: "Numvaga mfite inzozi zo kuzajya kwiga mbamo nanjye ariko nkabona ntaho bizaturuka kuko nubwo narumuhanga ntago ubushobozi bwari kuboneka.Nsoje amashuri abanza rero nagize amanota meza ku bw'amahirwe ASEF intoranya mubana bagomba gufashwa, byabaye ikiraro cyo gutuma inzozi zanjye ziba impamo.Ubu ndi mu mwaka wa gatatu wamashuri yisumbuye ariko mpora nkora cyane kugira ngo mbashe kuzagera kuri byinshi muhazaza hanjye ndetse nzahindure n'ubuzima bw'umuryango wanjye.",
      },
      {
        text: 'Ndashimira cyane ASEF ndetse  nshishikariza abanyeshuri bagenzi banjye kudapfusha ubusa amahirwe twagize!',
      },
      {
        text: 'Murakoze!',
      },
    ],
  },
];

const Story = ({ chosenStory, setChosenStory, setNavigateToSection }) => {
  return (
    <div className="w-full h-fit">
      <div className="w-full flex flex-col px-6 md:px-12 py-6 lg:px-32 lg:py-12">
        <div id="main_story" className="w-full mb-6">
          {stories.map((story) => {
            if (story.id === chosenStory) {
              return (
                <div className="w-full flex flex-col gap-6">
                  <div className="w-full flex flex-col lg:flex-row items-start gap-6">
                    <div className="w-full lg:w-1/2 rounded-2xl overflow-hidden">
                      <img
                        src={story.image}
                        alt=""
                        className="w-full max-h-[400px] "
                      />
                    </div>
                    <div className="w-full lg:w-1/2 flex flex-col gap-4 mt-12">
                      <h1 className="font-quicksand font-semibold text-2xl">
                        {story.name}
                      </h1>
                      <p className="font-rubik text-sm text-fontGrey">
                        {story.role}
                      </p>
                      <p className="font-rubik text-fontGrey text-lg">
                        {story.paragraphs[0].text}
                      </p>
                    </div>
                  </div>
                  {story.paragraphs.map((paragraph, index) => {
                    if (index === 0) {
                      return null;
                    } else {
                      return (
                        <p className="font-rubik text-fontGrey text-lg">
                          {paragraph.text}
                        </p>
                      );
                    }
                  })}
                </div>
              );
              // return <p>{story.name}</p>;
            } else {
              return null;
            }
          })}
        </div>
        <p className="font-semibold text-primary text-lg">Other Stories </p>
        <div className="flex flex-col w-full">
          <div className="flex flex-row flex-wrap w-full">
            {stories.map((story) => {
              if (story.id !== chosenStory) {
                return (
                  <div className="flex flex-col lg:flex-row gap-6 items-start w-full lg:w-[49%] px-1 py-4">
                    <div className="w-fit lg:max-w-[160px] h-[160px] rounded-2xl bg-cover overflow-hidden mx-auto">
                      <img
                        src={story.image}
                        alt=""
                        className="w-full h-full bg-cover"
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full lg:w-2/3">
                      <h2 className="font-bold font-quicksand text-center lg:text-start">
                        {story.name}
                      </h2>
                      <p className="font-rubik text-sm text-fontGrey text-center lg:text-start">
                        {story.role}
                      </p>

                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-2">
                          <BiSolidQuoteAltLeft
                            size={20}
                            color="#FDB935"
                            className="mx-auto lg:mx-0"
                          />
                          <p>
                            {story.paragraphs[0].text.length > 200
                              ? story.paragraphs[0].text.slice(0, 200) + '...'
                              : story.paragraphs[0].text}
                          </p>
                        </div>

                        <a
                          href="#main_story"
                          className="flex gap-2 items-center w-fit cursor-pointer"
                          onClick={() => {
                            setChosenStory(story.id);
                          }}
                        >
                          <p className="text-secondary font-rubik">Read more</p>
                          <LuMoveRight size={20} color="#FDB935" />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          <Link to="/stories" className="w-fit self-end mt-4 mx-auto lg:mx-0">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center py-1 px-4">
                  <p className="text-white font-rubik font-normal">
                    View More Stories
                  </p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'text-white w-max ml-0 flex self-end'}
            />
          </Link>
        </div>
      </div>
      <Partners />
      <Footer setNavigateToSection={setNavigateToSection} />
    </div>
  );
};

export default Story;
