import React from 'react';
import { LuMoveRight } from 'react-icons/lu';
import Partners from '../partners/Partners';
import Footer from '../../components/Footer';
import c2k from '../../assets/c2k.png';
import AC from '../../assets/AC.png';

const initiativeArray = [
  {
    id: 1,
    image: c2k,
    date: 'Feb 16, 2024',
    title: 'Coming to Kigali (C2K)',
    paragraphs: [
      {
        text: 'It was an activity that took place from 2014 to 2018, where all students who got the first position, annually, in their classes were awarded a trip to Kigali. For most of them, it was their first time to coming Kigali.',
      },

      {
        text: 'ASEF tried to arrange visits that would leave a higher level of thinking to students and help in their goal setting. In visited places, we can enumerate Kigali International Airport, the USA Embassy in Kigali, Kigali Convention Centre, Kigali Library, Fazenda Sengha, Kigali City Tower (KCT), UR-CST (the University of Rwanda, College of Sciences and Technology), Gahanga Cricket Stadium, so and so on',
      },
      {
        text: "Due to the will of every student to attend Coming to Kigali, the number of students' performance improved a lot. We had to organize 2 days of Coming to Kigali to be able to make it happen.",
      },
      {
        text: 'This made us think about preparing them for a higher target, which was something more competitive but also more educative. This is where the idea of Annual Camping (AC) came from.',
      },
    ],
  },
  {
    id: 2,
    image: AC,
    date: 'Feb 16, 2024',
    title: 'Annual Camping (AC)',
    paragraphs: [
      {
        text: 'In December 2018, more than 100 students did the interview. Among them, 35 were selected to do the camp for one week in April 2019. In July 2019 ASEF met them near their districts to follow up with the lessons they learned in April. In December 2019, ASEF conducted group interviews that had a specialty of how to be good storytellers.',
      },
      {
        text: 'ASEF conducted a camp in April 2019 at St Andre College in Kigali. There, all students were excited to use the best smart labs in Rwanda, but also it opened their eyes to how the technology is being developed, although they were bright they had never been exposed to such technology. After the sessions, you could realize how they were freer to express themselves and adjust their ideas with happy faces due to lessons they got such as public speaking, essay writing, and how to use available resources properly like computers and the internet. ',
      },
      {
        text: 'It was not easy to have students from 11 districts logged together for 1 week, and ASEF appreciates the time and a lot of effort put in by Peace Corps Volunteers, Education Advisor in the US Embassy in Kigali to prepare materials used and being near students during the camp. Hands up to Jessica Linus for helping in group storytelling lessons in December 2020.',
      },
      {
        text: 'This activity had an impact on students, since from then, we started to have a higher number of students getting abroad scholarships and good universities.',
      },
      {
        text: 'Currently, this program stopped and ASEF is looking for a way to revamp it shortly since it has been affecting the whole group of ASEF students, where all of them want to be the first of their class to become AC candidates. ASEF is also partnering with different institutions, schools, or organizations that share the same spirit of creating in students the spirit of aiming higher.',
      },
    ],
  },
];

const Initiative = ({
  chosenInitiative,
  setChosenInitiative,
  setNavigateToSection,
}) => {
  return (
    <div className="w-full h-fit">
      <div className="w-full px-6 lg:px-32 pb-12 pt-6">
        <div className="flex flex-col lg:flex-row items-start w-full gap-6">
          <div className="flex flex-col lg:w-2/3">
            {initiativeArray.map((initiative) => {
              if (initiative.id == chosenInitiative) {
                return (
                  <div className="flex flex-col w-full">
                    <div className=" bg-black bg-cover w-full overflow-hidden rounded-3xl max-h-[500px] object-contain">
                      <img
                        src={initiative.image}
                        alt=""
                        className="w-full h-full object-contain"
                      />
                    </div>

                    <div className=" flex items-center gap-4 my-3">
                      <p className="text-fontGrey">{initiative.date}</p>
                    </div>

                    <h1 className="mt-1 font-bold text-2xl text-start">
                      {initiative.title}
                    </h1>

                    <div className="mt-4 flex flex-col text-lg font-rubik text-fontGrey gap-4">
                      {initiative.paragraphs.map((paragraph) => {
                        return <p>{paragraph.text}</p>;
                      })}
                    </div>

                    {/* <div className="my-6 lg:my-12 flex gap-6 font-rubik">
                    <p className="text-lg">Share</p>
                    <div className="flex items-center gap-4">
                      <FaInstagram size={20} color="#01A653" />
                      <FaFacebook size={20} color="#01A653" />
                      <FaXTwitter size={20} color="#01A653" />
                    </div>
                  </div> */}
                  </div>
                );
              } else return null;
            })}
          </div>
          <div className="flex flex-col lg:w-1/3 bg-grey py-4 px-8 rounded-2xl font-rubik">
            <p className="text-lg font-semibold mb-4">Recent Initiatives</p>
            <div className="flex flex-col gap-4">
              {initiativeArray.map((initiative) => {
                return (
                  <div className="bg-white rounded-xl p-4 flex flex-col">
                    <div className=" flex items-center gap-4 my-3">
                      <p className="text-secondary">{initiative.category}</p>
                      <p className="text-fontGrey">{initiative.date}</p>
                    </div>
                    <p className="font-semibold mb-4">
                      {' '}
                      {initiative.title.length > 40
                        ? initiative.title.slice(0, 40) + '...'
                        : initiative.title}
                    </p>
                    <p className="">
                      {initiative.paragraphs[0].text.length > 80
                        ? initiative.paragraphs[0].text.slice(0, 80) + '...'
                        : initiative.paragraphs[0].text}
                    </p>

                    <div
                      className="mt-2 flex gap-2 items-center w-fit cursor-pointer"
                      onClick={() => {
                        setChosenInitiative(initiative.id);
                      }}
                    >
                      <p className="text-secondary font-rubik">Read more</p>
                      <LuMoveRight size={20} color="#FDB935" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Partners />
      <Footer setNavigateToSection={setNavigateToSection} />
    </div>
  );
};

export default Initiative;
