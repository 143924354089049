import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { IoIosArrowDown } from 'react-icons/io';
import { BsTelephone } from 'react-icons/bs';
import { CiMail } from 'react-icons/ci';
import logo from '../assets/logo.png';
import Button from './Button';
import Collapsible from './Collapsible';

const TopBar = ({ setNavigateToSection }) => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [burger_class, setBurgerClass] = useState(false);
  const [current, setCurrent] = useState('');
  const location = useLocation();
  const onClick = (e) => {
    setCurrent(e.key);
  };

  const updateMenu = () => {
    setBurgerClass(!burger_class);
  };

  const reachToUs = () => {
    if (location.pathname === '/') {
    } else {
      navigate('/');
    }
    setTimeout(() => {
      anchorRef.current.click();
    }, 100);
  };

  const menuItems = [
    {
      name: 'Home',
      navigatable: true,
      to: '/',
      has_submenu: false,
    },
    {
      name: 'About Us',
      navigatable: true,
      to: '/about-us',
      has_submenu: true,
      submenu: [
        {
          name: 'Who We Are',
          to: '/about-us',
          navigatable: true,
          navigate: '#who-we-are',
        },
        {
          name: 'Our Founder',
          to: '/about-us',
          navigatable: true,
          navigate: '#founder',
        },
        {
          name: 'Our Sponsorship',
          to: '/about-us',
          navigatable: true,
          navigate: '#sponsorship',
        },
        {
          name: 'Meet Our Team',
          to: '/about-us',
          navigatable: true,
          navigate: '#team',
        },
      ],
    },
    {
      name: 'Our Programs',
      navigatable: true,
      to: '/programs',
      has_submenu: true,
      submenu: [
        {
          name: 'New Intake',
          to: '/programs/new-intake',
          navigatable: false,
        },
        {
          name: 'Advisory Meetings',
          to: '/programs/advisory-meetings',
          navigatable: false,
        },
        {
          name: 'Supply Distribution',
          to: '/programs/supplies',
          navigatable: false,
        },
        {
          name: 'Graduate Meetings',
          to: '/programs/graduate-meetings',
          navigatable: false,
        },

        {
          name: 'JADF Activities',
          to: '/programs/jadf',
          navigatable: false,
        },

        {
          name: 'School Visits',
          to: '/programs/school-visits',
          navigatable: false,
        },
      ],
    },
    {
      name: 'Stay Informed',
      navigatable: false,
      to: '/news',
      has_submenu: true,
      submenu: [
        {
          name: 'News & Updates',
          to: '/news',
          navigatable: false,
        },
        {
          name: 'Stories',
          to: '/stories',
          navigatable: false,
        },
        {
          name: 'Newsletter',
          to: '/newsletter',
          navigatable: false,
        },
        {
          name: 'Opportunities',
          to: '/opportunities',
          navigatable: false,
        },
      ],
    },
  ];

  return (
    <div id="top_bar" className="relative flex flex-col font-rubik">
      <div className="bg-lightGrey hidden lg:flex items-center justify-between px-6 md:px-12 xlg:px-32  py-2 max-h-[40px]">
        <div className="flex items-center gap-4">
          {/*  */}
          <a
            href="https://www.instagram.com/asefrwanda/"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-black rounded-full p-[6px] cursor-pointer"
          >
            <FaInstagram size={15} color="#FFFFFF" />
          </a>
          {/*  */}
          <a
            href="https://www.facebook.com/ASEFRwanda"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-black rounded-full p-[6px] cursor-pointer"
          >
            <FaFacebook size={15} color="#FFFFFF" />
          </a>

          <a
            href="https://twitter.com/ASEFRwanda"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-black rounded-full p-[6px] cursor-pointer"
          >
            <FaXTwitter size={15} color="#FFFFFF" />
          </a>
        </div>

        <div className="flex items-center gap-4 text-[#4D4C4C]">
          <div className="flex items-center gap-2">
            <BsTelephone size={15} />
            <p className="">2733</p>
          </div>

          <div className="flex items-center gap-2">
            <CiMail size={15} />
            <p className="">asef@asefrwanda.org</p>
          </div>
        </div>
      </div>
      <div className="relative flex items-center justify-between px-6 md:px-12 xlg:px-32 py-4 font-rubik max-h-[80px] w-full">
        <Link to={'/'} className="mr-4 h-[75px] w-[94px] object-contain">
          <img
            src={logo}
            className="w-full h-full object-contain"
            alt="ASEF-logo"
            srcset=""
          />
        </Link>

        <div className="hidden lg:flex items-center gap-6 lg:gap-12 w-fit">
          <div className="w-full">
            <div className="flex items-center gap-8 font-rubik ">
              {menuItems.map((item) => {
                if (item.has_submenu) {
                  return (
                    <div className="group flex flex-col gap-2 h-fit relative">
                      {item.navigatable ? (
                        <Link
                          to={item.to}
                          className="cursor-pointer flex items-center gap-2 hover:text-primary border-b-[2px] border-b-white hover:border-b-primary text-lg"
                        >
                          <h1 className="w-fit">{item.name}</h1>{' '}
                          <IoIosArrowDown />
                        </Link>
                      ) : (
                        <div
                          to={item.to}
                          className="cursor-pointer flex items-center gap-2 hover:text-primary border-b-[2px] border-b-white hover:border-b-primary text-lg"
                        >
                          <h1 className="w-fit">{item.name}</h1>{' '}
                          <IoIosArrowDown />
                        </div>
                      )}

                      <div className="hidden absolute group-hover:block top-[20px] z-50 pt-[10px] w-[200px] ">
                        <div className="bg-white rounded-lg  shadow-lg border border-lightGrey">
                          {item.submenu.map((submenu) => {
                            return (
                              <Link
                                to={submenu.to}
                                className="flex items-center font-rubik text-base px-3 py-2 w-full hover:bg-lightGrey hover:text-primary"
                                onClick={() => {
                                  setNavigateToSection(submenu.navigate);
                                }}
                              >
                                <p className="w-[90%] mx-auto text-start">
                                  {submenu.name}
                                </p>
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <Link
                      to={item.to}
                      className="hover:text-primary mb-[2px] text-lg"
                    >
                      {item.name}
                    </Link>
                  );
                }
              })}
            </div>
          </div>
          <Button
            type="button"
            onClick={() => {
              reachToUs();
            }}
            content={
              <div className="flex items-center justify-center px-4 py-1">
                <p className="text-white font-rubik">Reach To Us</p>
              </div>
            }
            loading={false}
            btnColor="primary"
            Style={'text-white min-w-[160px] ml-0'}
          />
        </div>

        <div className={`burger_Menu mb-5`} onClick={updateMenu}>
          <div
            className={
              burger_class ? 'burger_Bar clicked' : 'burger_Bar unclicked'
            }
          ></div>
          <div
            className={
              burger_class ? 'burger_Bar clicked' : 'burger_Bar unclicked'
            }
          ></div>
          <div
            className={
              burger_class ? 'burger_Bar clicked' : 'burger_Bar unclicked'
            }
          ></div>
        </div>
      </div>

      <div
        className={`displayMenu ${
          burger_class
            ? 'visible w-full h-screen shadow-xl border-t border-t-[#eeeded]'
            : 'hidden h-[0px]'
        }`}
      >
        <ul className="flex flex-col gap-6 list-none pl-6 pt-4 ">
          <Link
            to={'/'}
            className="text-lg cursor-pointer"
            onClick={() => {
              updateMenu();
            }}
          >
            Home
          </Link>

          <Collapsible
            key={1}
            Style={''}
            heading={<h1 className="text-lg">About Us</h1>}
            body={
              <div className="flex flex-col gap-2 cursor-pointer text-fontGrey">
                <Link
                  to={'about-us'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                    setNavigateToSection('#who-we-are');
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">Who we are</p>
                </Link>
                <Link
                  to={'about-us'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                    setNavigateToSection('#founder');
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">Our Founder</p>
                </Link>
                <Link
                  to={'about-us'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                    setNavigateToSection('#sponsorship');
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">
                    Our Sponsorship
                  </p>
                </Link>
                <Link
                  to={'about-us'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                    setNavigateToSection('#team');
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">Meet Our Team</p>
                </Link>
              </div>
            }
          />

          <Collapsible
            key={2}
            Style={''}
            heading={<h1 className="text-lg">Programs</h1>}
            body={
              <div className="flex flex-col gap-2 cursor-pointer text-fontGrey">
                <Link
                  to={'programs/new-intake'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">New Intake</p>
                </Link>
                <Link
                  to={'programs/advisory-meetings'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">
                    Advisory Meetings
                  </p>
                </Link>
                <Link
                  to={'programs/supplies'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">
                    Supply Distribution
                  </p>
                </Link>
                <Link
                  to={'programs/graduate-meetings'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">
                    Graduate Meetings
                  </p>
                </Link>
                <Link
                  to={'programs/jadf'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">
                    JADF Activities
                  </p>
                </Link>
                <Link
                  to={'programs/school-visits'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">School Visits</p>
                </Link>
              </div>
            }
          />

          <Collapsible
            key={3}
            Style={''}
            heading={<h1 className="text-lg">Stay Informed</h1>}
            body={
              <div className="flex flex-col gap-2 cursor-pointer text-fontGrey">
                <Link
                  to={'/news'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">News & Updates</p>
                </Link>
                <Link
                  to={'/stories'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">Stories</p>
                </Link>
                <Link
                  to={'newsletter'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">Newsletter</p>
                </Link>
                <Link
                  to={'opportunities'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">Opportunities</p>
                </Link>

                <Link
                  to={'initiatives'}
                  className="font-rubik text-base"
                  onClick={() => {
                    updateMenu();
                  }}
                >
                  <p className="p-2 hover:bg-grey rounded-lg">
                    Special Initiatives
                  </p>
                </Link>
              </div>
            }
          />
        </ul>
      </div>
      {/* Anchor tag with dynamic href set */}
      <a ref={anchorRef} href="#contact" style={{ display: 'none' }}></a>
    </div>
  );
};

export default TopBar;
