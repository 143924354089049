import React from 'react';
import AllNews from './news_pages/AllNews';
import Partners from '../../partners/Partners';
import Footer from '../../../components/Footer';
const News = ({ setChosenNews, setNavigateToSection }) => {
  return (
    <div>
      <AllNews setChosenNews={setChosenNews} />
      <Partners />
      <Footer setNavigateToSection={setNavigateToSection} />
    </div>
  );
};

export default News;
