import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import { LuMoveRight } from 'react-icons/lu';
import Button from '../../components/Button';
import programs_frame from '../../assets/programs_frame.png';
import happy_children from '../../assets/happy_children.jpg';
import basketball_field from '../../assets/basketball_field.jpg';
import { FaPlus } from 'react-icons/fa';
import new_intake from '../../assets/new_intake.jpg';
import school_visits from '../../assets/school_visits.jpg';

const dummyPrograms = [
  {
    title: 'New intake',
    image: new_intake,
    description:
      'ASEF collaborates with the districts and sectors authorities typically District Education officers (DEOs), Vice Mayor Social Affairs, Director of Social Affairs, JADF Officers, Sector Education',
    routing: '/programs/new-intake',
  },
  {
    title: 'Advisory Meetings',
    image: happy_children,
    description:
      'ASEF does not believe that paying tuition fees and providing school supplies to our students is enough for them to succeed in life. We think that they need more than that and we are more than ready to do our best to help them in various ways.',
    routing: '/programs/advisory-meetings',
  },
  {
    title: 'School Visits',
    image: school_visits,
    description:
      "Jolly Nyiratunga - ASEF's Community Outreach Director talks about her experience with school visits.",
    routing: '/programs/school-visits',
  },
  {
    title: 'Graduate Meetings (AGM)',
    image: basketball_field,
    description:
      'ASEF beneficiaries complete their scholarship with ASEF at the end of senior 6. Those who finish senior 6, are called ASEF graduates because they are no longer supported by ASEF.',
    routing: '/programs/graduate-meetings',
  },
];

const BriefPrograms = () => {
  const programRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [studentCount, setStudentCount] = useState(1);
  const [districtCount, setDistrictCount] = useState(1);
  const [graduateCount, setGraduateCount] = useState(1);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isVisible) {
          setIsVisible(true);
          setStudentCount(1200);
          setDistrictCount(11);
          setGraduateCount(2500);
        }
      });
    }, options);

    if (programRef.current) {
      observer.observe(programRef.current);
    }

    return () => {
      if (programRef.current) {
        observer.unobserve(programRef.current);
      }
    };
  }, [isVisible]);

  return (
    <div
      id="brief_programs"
      className="flex flex-col lg:flex-row w-full h-fit lg:min-h-full"
      ref={programRef}
    >
      <div
        className="h-full w-full lg:w-1/2 bg-cover bg-center px-6 md:px-12 lg:px-24 py-12"
        style={{ backgroundImage: `url(${programs_frame})` }}
      >
        <div className="flex flex-col">
          <h2 className="font-bold text-quicksand text-center lg:text-start text-3xl lg:text-[41px] text-secondary mb-6">
            Current Status
          </h2>

          <div className="flex flex-wrap justify-center md:justify-start items-center gap-6 md:gap-12 lg:gap-16 w-fit mx-auto lg:mx-0">
            <div className="flex flex-col gap-2">
              <div className="flex gap-1 items-center">
                <p className="font-bold text-white text-2xl lg:text-[40px]">
                  {isVisible ? (
                    <CountUp start={1} end={1200} delay={0}>
                      {({ countUpRef }) => (
                        <div>
                          <span className="text-3xl" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  ) : (
                    studentCount
                  )}
                </p>
                <FaPlus size={20} color="#fff" />
              </div>
              <p className="font-rubik text-lg text-white">Students</p>
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex gap-1 items-center">
                <p className="font-bold text-white text-2xl lg:text-[40px]">
                  {isVisible ? (
                    <CountUp start={1} end={10} delay={0} duration={5}>
                      {({ countUpRef }) => (
                        <div>
                          <span className="text-3xl" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  ) : (
                    districtCount
                  )}
                </p>
              </div>
              <p className="font-rubik text-lg text-white">Districts</p>
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex gap-1 items-center">
                <p className="font-bold text-white text-2xl lg:text-[40px]">
                  {isVisible ? (
                    <CountUp start={1} end={2500} delay={0} duration={3}>
                      {({ countUpRef }) => (
                        <div>
                          <span className="text-3xl" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  ) : (
                    graduateCount
                  )}
                </p>
                <FaPlus size={20} color="#fff" />
              </div>
              <p className="font-rubik text-lg text-white">Graduates</p>
            </div>
          </div>

          <div className="w-fit self-center lg:w-full rounded-2xl bg-[#13B965]/75  py-6 flex flex-wrap gap-3 lg:gap-12 pl-8 lg:pl-6 pr-6 lg:pr-2 text-white text-sm lg:text-lg my-12">
            <div className="flex flex-col gap-3">
              <p className="w-fit">Burera</p>
              <p className="w-fit">Musanze</p>
              <p className="w-fit">Rulindo</p>
            </div>

            <div className="flex flex-col gap-3">
              <p className="w-fit">Gakenke</p>
              <p className="w-fit">Nyanza</p>
              <p className="w-fit">Ruhango</p>
            </div>

            <div className="flex flex-col gap-3">
              <p className="w-fit">Gisagara</p>
              <p className="w-fit">Nyabihu</p>
              <p className="w-fit">Rwamagana</p>
            </div>

            <div className="flex flex-col gap-3">
              <p className="w-fit">Kamonyi</p>
            </div>
          </div>

          {/* <div className="w-full rounded-2xl bg-[#13B965]/75 py-6 pl-16 lg:pl-6 pr-6  flex flex-wrap gap-3 lg:gap-12 text-white text-lg my-12 ">
            <div className="flex flex-col gap-3">
              <p className="w-[100px]">Burera</p>
              <p className="w-[100px]">Musanze</p>
              <p className="w-[100px]">Rulindo</p>
            </div>

            <div className="flex flex-col gap-3 ml-6 md:ml-0">
              <p className="w-[100px]">Gakenke</p>
              <p className="w-[100px]">Nyanza</p>
              <p className="w-[100px]">Rutsiro</p>
            </div>

            <div className="flex flex-col gap-3">
              <p className="w-[100px]">Gisagara</p>
              <p className="w-[100px]">Nyabihu</p>
              <p className="w-[100px]">Rwamagana</p>
            </div>

            <div className="flex flex-col ml-6 md:ml-0 gap-3">
              <p className="w-[100px]">Kamonyi</p>
              <p className="w-[100px]">Ruhango</p>
            </div>
          </div> */}

          <div className="flex flex-col">
            <p className="text-white font-rubik text-base text-center lg:text-lg lg:text-start mb-12">
              ASEF collaborates with the districts and sectors authorities to
              identify the vulnerable and bright students.
            </p>

            <p className="text-white font-rubik text-base text-center lg:text-lg lg:text-start">
              ASEF does not believe that paying tuition fees and providing
              school supplies to our students is enough for them to succeed in
              life. We organize different activities to empower them such as
              advisory meetings, school visits, graduate meetings, etc..
            </p>
          </div>
        </div>
      </div>
      <div className="h-full w-full lg:w-1/2 px-6 md:px-12 lg:px-16 py-12">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4 mb-6">
            <div className="bg-secondary h-[4px] w-[80px]"></div>
            <h1 className="text-secondary font-semibold font-rubik text-lg">
              PROGRAMS
            </h1>
          </div>
          <div className="w-full flex flex-col gap-6">
            {dummyPrograms?.map((program, i) => {
              return (
                <div
                  key={i}
                  className="w-full flex items-start overflow-hidden gap-4"
                >
                  <div className=" float-left w-[130px] h-full overflow-hidden">
                    <div className="flex flex-col h-[110px] overflow-hidden rounded-2xl">
                      <img
                        src={program.image}
                        alt=""
                        className="w-full h-full object-fill transition-all duration-300 ease-linear hover:scale-110"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 w-fit px-2 h-full oveflow-hidden">
                    <h2 className="font-bold text-opacity-70 w-full font-quicksand text-lg">
                      {program.title.length > 40
                        ? program.title.slice(0, 40) + '...'
                        : program.title}
                    </h2>
                    <p className="text-opacity-60 w-full font-nunito text-fontGrey">
                      {program.description.length > 150
                        ? program.description.slice(0, 150) + '...'
                        : program.description}
                    </p>
                    <Link
                      to={program.routing}
                      className="flex gap-2 items-center w-fit cursor-pointer"
                    >
                      <p className="text-secondary font-rubik">Read more</p>
                      <LuMoveRight size={20} color="#FDB935" />
                    </Link>
                  </div>
                </div>
              );
            })}

            <Link to="/programs" className="flex self-center lg:self-end">
              <Button
                type="button"
                content={
                  <div className="flex items-center justify-center py-1 px-4">
                    <p className="text-white font-rubik font-normal text-lg">
                      View All Programs
                    </p>
                  </div>
                }
                loading={false}
                btnColor="secondary"
                Style={'text-white w-max mt-4 flex self-end'}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BriefPrograms;
