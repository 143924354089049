import React from 'react';
import { Link } from 'react-router-dom';
import Partners from '../../../partners/Partners';
import Footer from '../../../../components/Footer';
import { LuMoveRight } from 'react-icons/lu';
import { CgDanger } from 'react-icons/cg';

const dummyEvents = [
  {
    title: 'New intake',
    image: '',
    description:
      'ASEF collaborates with the districts and sectors authorities typically District Education officers (DEOs), Vice Mayor Social Affairs, Director of Social Affairs, JADF Officers, Sector Education',
    routing: '/programs/new-intake',
  },
  {
    title: 'Advisory Meetings',
    image: '',
    description:
      'ASEF does not believe that paying tuition fees and providing school supplies to our students is enough for them to succeed in life. We think that they need more than that and we are more than ready to do our best to help them in various ways.',
    routing: '/programs/advisory-meetings',
  },
  {
    title: 'School Visits',
    image: '',
    description:
      "Jolly Nyiratunga - ASEF's Community Outreach Director talks about her experience with school visits.",
    routing: '/programs/school-visits',
  },
];

const GraduateMeetings = ({ setNavigateToSection }) => {
  return (
    <div className="w-full h-fit lg:h-full">
      <div className="w-full px-6 lg:px-32 py-12">
        <div className="flex flex-col lg:flex-row items-start w-full gap-6">
          <div className="flex flex-col lg:w-2/3">
            <div className="flex flex-col w-full">
              <h1 className="mb-4 font-bold text-2xl text-start text-primary">
                GRADUATE MEETINGS
              </h1>
              <div className="w-full rounded-lg lg:rounded-3xl overflow-auto">
                <iframe
                  src="https://www.youtube.com/embed/SeMsSkCUbjs?si=MOa3zb9qSiIQArZ7"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  className="w-full aspect-video my-auto"
                ></iframe>
              </div>

              <div className="mt-4 flex flex-col text-lg font-rubik gap-6">
                <p>
                  ASEF beneficiaries complete their scholarship with ASEF at the
                  end of senior 6. Those who finish senior 6, are called ASEF
                  graduates because they are no longer supported by ASEF. Most
                  of them continue to study at different universities and
                  colleges. The big part continues their studies in Rwanda, some
                  go to UR, IPRCs, Kepler, ALU, UGHE, or RICA. But we have
                  others who apply for abroad scholarships and study in the USA,
                  Germany and Asia.
                </p>
                <p>
                  Even if ASEF does not support graduates with tuition and
                  supplies, ASEF also gives them guidance for their future after
                  leaving the program through meetings called “ASEF Graduates
                  Meetings (AGM)”. Because of thelack of internet and computers,
                  some of them lack mostly information about what's going on.
                  ASEF helps them doing the application process in Rwandan
                  universities where they are allocated. ASEF takes that
                  opportunity to share with them different abroad scholarship
                  links to apply and other opportunities.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mx-auto lg:mx-0 lg:justify-start lg:w-1/3 bg-grey py-4 px-8 rounded-2xl font-rubik">
            <p className="text-lg font-semibold mb-4">Other Programs</p>
            <div className="flex flex-col gap-4">
              {dummyEvents.map((program) => {
                return (
                  <div className="bg-white rounded-xl p-4 flex flex-col">
                    <p className="font-semibold mb-4">
                      {' '}
                      {program.title.length > 40
                        ? program.title.slice(0, 40) + '...'
                        : program.title}
                    </p>
                    <p className="">
                      {program.description.length > 80
                        ? program.description.slice(0, 80) + '...'
                        : program.description}
                    </p>

                    <Link
                      to={program.routing}
                      className="mt-2 flex gap-2 items-center w-fit cursor-pointer"
                      onClick={() => {}}
                    >
                      <p className="text-secondary font-rubik">Read more</p>
                      <LuMoveRight size={20} color="#FDB935" />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Partners />
      <Footer setNavigateToSection={setNavigateToSection} />
    </div>
  );
};

export default GraduateMeetings;
