import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import About from '../pages/about/detail/About';
import News from '../pages/news/detail/News';
import Programs from '../pages/programs/detail/Programs';
import Stories from '../pages/stories/detail/Stories';
import LandingPage from '../pages/LandingPage';
import TopBar from '../components/TopBar';

const mainRoutes = ({
  setChosenNews,
  setChosenStory,
  setNavigateToSection,
}) => {
  return (
    <>
      <Routes>
        <Route
          path={`/`}
          element={
            <LandingPage
              setNavigateToSection={setNavigateToSection}
              setChosenNews={setChosenNews}
              setChosenStory={setChosenStory}
            />
          }
        />
      </Routes>
    </>
  );
};

export default mainRoutes;
